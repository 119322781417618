import Vue from 'vue'
import GoogleMap from './components/google/GoogleMap'
import GoogleMapPointer from './components/google/GoogleMapPointer'
import GoogleMapController from './components/google/GoogleMapController'
import EventBus from "./components/EventBus"

window.EventBus = window.EventBus || EventBus

Vue.component('GoogleMap',GoogleMap)
Vue.component('GoogleMapPointer',GoogleMapPointer)
Vue.component('GoogleMapController',GoogleMapController)

