<template>
    <div>        
        <button @click="filterPointers('filter1')" class="uk-button uk-button-primary">Filter 1</button>
        <button @click="filterPointers('filter2')" class="uk-button uk-button-primary">Filter 2</button>
        <button @click="filterPointers('filter3')" class="uk-button uk-button-primary">Filter 3</button>
        <button @click="filterPointers('')" class="uk-button uk-button-default">Clear Filters</button>
    </div>
</template>

<script>
import EventBus from "../EventBus"

export default {
    name: "GoogleMapController",
    data(){ return {} },
    methods: {
        filterPointers(filter){
            EventBus.$emit( "googlemaps/pointer/filter", {filter} )
        }
    }
}
</script>

<style></style>
