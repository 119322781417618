const CustomPopupFactory = {

    createPopupClass(google){

        let popup_class = function(position,content) {

            this.position = position
            this.anchor = document.createElement('div')
            this.anchor.classList.add('hx-popup-anchor')
            this.anchor.appendChild(content)

            let close = document.createElement('div')
            close.classList.add('hx-popup-close')            
            
            content.classList.add('hx-popup-content')
            content.appendChild(close)

            close.addEventListener("click", () => {
                this.setMap(null);
            })

        }

        popup_class.prototype = Object.create(google.maps.OverlayView.prototype)

        popup_class.prototype.onAdd = function() {
            this.getPanes().floatPane.appendChild(this.anchor);
        }

        popup_class.prototype.onRemove = function() {
            if (this.anchor.parentElement) {
                this.anchor.parentElement.removeChild(this.anchor);
            }
        }

        popup_class.prototype.draw = function() {
            var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
            // Hide the popup when it is far out of view.
            var display =
                Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
                'block' :
                'none';
        
            if (display === 'block') {
              this.anchor.style.left = divPosition.x + 'px';
              this.anchor.style.top = divPosition.y + 'px';
            }
            if (this.anchor.style.display !== display) {
              this.anchor.style.display = display;
            }
          }

        return popup_class
    }

}

export default CustomPopupFactory