<template>
<div class="hx-gmap-container">
    <div class="hx-gmap" ref="googleMap"></div>
    <slot></slot>
</div>
</template>

<script>
import UIkit from "uikit";
import EventBus from "../EventBus";
import CONFIG from "./GoogleMapConfig"

export default {
  name: 'GoogleMap',
  components: {},
  props: {
      name: String,
      lat: Number,
      long: Number,
      zoom: {
          type: Number,
          default: 10
      }
  },
  data(){
    return {
      loadMap: false,
      ready: false,
      loaded: false,
      filter: "",
      map: {}
    }
  },
  watch:{
    loaded: function(loaded) {  if( this.loadMap && window.isGooglemapsReady && !this.ready ) this.initGoogleMaps("from loaded event") },
    loadMap: function(loaded) { if( this.loadMap && window.isGooglemapsReady && !this.ready ) this.initGoogleMaps("from inview event") }
  },
  created(){
    EventBus.$on('googlemaps/loaded', () => {
      this.loaded = true
    })
  },
  mounted(){
    EventBus.$on('googlemaps/pointer/filter', (e) => {
      this.filter = e.filter
    });

    UIkit.util.on(this.$el, 'inview', () => {
      this.loadMap = true
    })
    UIkit.scrollspy(this.$el, {})

    if( this.loadMap && window.isGooglemapsReady && !this.ready ) this.initGoogleMaps("when moutend")
  },
  methods: {
    initGoogleMaps(reason){
      if(process.env.NODE_ENV == "development"){
        console.log(`Init google maps: ${reason}`)
      }

      let center = new google.maps.LatLng(this.lat, this.long)
      const options = {
        zoom: this.zoom,
        minZoom: 5,
        maxZoom: 20,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
        center: center,
        disableDefaultUI: false,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true
      }

      this.map = new google.maps.Map(this.$refs.googleMap, options);

      this.map.setOptions({styles: CONFIG.styles || []});

      this.map.ready = true
      this.ready = true
    }
  }
};
</script>
