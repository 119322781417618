<script>
import EventBus from "../EventBus"
import CustomPopupFactory from "./CustomPopupFactory"

export default {
    name: "GoogleMapPointer",
    render: function (createElement) {
        return createElement(
            'div', this.$slots.default
        )
    },
    props: {
        image: String,
        lat: Number,
        long: Number,
        title: String,
        category:  {
            type: String,
            default: ""
        },
        scale: {
            type: Number,
            default: 1
        },
        fill: {
            type: String,
            default: "000000"
        },
        stroke: {
            type: String,
            default: "ffffff"
        },
        clickable: {
            type: Boolean,
            default: false
        },
        popup: {
            type: Boolean,
            default: false
        },
        size: {
            type: Array,
            default: () => [34,34]
        },
        anchor: {
            type: Array,
            default: () => [17,17]
        },
        zoomRange:{
            type: Array,
            default: () => [0,20]
        }
    },
    computed:{
        icon: function(){

            if( this.image !== undefined ){
                return {
                    url: this.image,
                    origin: new google.maps.Point(0, 0),
                    scaledSize: new google.maps.Size(this.size[0],this.size[1]),
                    anchor: new google.maps.Point(this.anchor[0],this.anchor[1])
                }
            }else{
                return {
                    path: "M14,1.429c-7.178,0-13,5.822-13,13c0,9.75,13,24.142,13,24.142s13-14.393,13-24.142C27,7.251,21.178,1.429,14,1.429z M14,19.072c-2.563,0-4.643-2.08-4.643-4.643c0-2.563,2.081-4.643,4.643-4.643c2.563,0,4.643,2.08,4.643,4.643 C18.643,16.992,16.563,19.072,14,19.072z",
                    fillColor: "#" + this.fill,
                    fillOpacity: 1,
                    anchor: new google.maps.Point(14,38),
                    strokeWeight: 2,
                    strokeColor: "#" + this.stroke,
                    scale: this.scale
                }
            }

        },
        marker: function() {

            if(!this.MarkerObject) this.MarkerObject = new google.maps.Marker()

            this.MarkerObject.setOptions({
                position: new google.maps.LatLng(this.lat, this.long),
                clickable: this.clickable,
                title: this.title,
                icon: this.icon
            })
            return this.MarkerObject
        },
        content: function() {
            return this.$el
        },
        showMarker: function(){
            let zoom = this.$parent.map.getZoom()
            return (this.zoomRange[0]) <= zoom && (this.zoomRange[1] >= zoom)
        }
    },
    watch:{
        "$parent.map.ready": function(val, oldVal){
            if(this.popup){
                const InfoBox = CustomPopupFactory.createPopupClass(google)
                this.infobox = new InfoBox(this.marker.getPosition(),this.content,this.closePopup)
            }
            this.addMarker()
            this.mapZoom(null)
        },
        "$parent.filter": function(val, oldVal){
            this.filterMarker(val)
        },
        clickable: function(clickable, oldVal){
            if(clickable){
                this.addClickHandlers()
            }else{
                this.removeClickHandlers()
            }
        }
    },
    methods: {
        addClickHandlers(){
            google.maps.event.clearListeners(this.marker, 'click');

            this.clickHandler = this.marker.addListener('click', (e)=>{
                if(this.popup){
                    this.marker.setVisible(false)
                    this.infobox.setMap(this.$parent.map);
                    EventBus.$emit('googlemaps/popup/close', this)
                }
            })

            EventBus.$on('googlemaps/popup/close', this.closePopup)
            EventBus.$on('googlemaps/map/zoom', this.mapZoom)
        },
        removeClickHandlers(){
            google.maps.event.removeListener(this.clickHandler);
            EventBus.$off('googlemaps/popup/close', this.closePopup)
            EventBus.$off('googlemaps/map/zoom', this.mapZoom)
            this.clickHandler = null
        },
        mapZoom(e){
            this.marker.setVisible(this.showMarker)
            this.infobox.setMap(null)
        },
        closePopup(el){
            if(el != this){
                this.marker.setVisible(this.showMarker)
                this.infobox.setMap(null)
            }
        },
        filterMarker(filter){

            if( filter == "" || this.category == filter ){
                this.marker.setVisible(this.showMarker)
            }else{
                this.marker.setVisible(false)
            }

            this.infobox.setMap(null)

        },
        removeMarker(){
            this.marker.setMap(null)
        },
        addMarker(){
            this.marker.setMap(this.$parent.map)
            if(this.clickable){
                this.addClickHandlers()
            }
        }
    }
}
</script>
